import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-sm-6" }
const _hoisted_6 = { class: "col-sm-6" }
const _hoisted_7 = { class: "d-flex justify-content-end mt-3" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_field = _resolveComponent("form-field")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_form_field, {
            type: "text",
            disabled: true,
            labelKey: "Product Name",
            labelValue: "Product Name",
            placeholderKey: "Product Name",
            placeholderValue: "Product Name",
            modelValue: _ctx.selectedGarment,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGarment) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_form_field, {
            type: "text",
            labelKey: "SKU",
            labelValue: "SKU",
            placeholderKey: "SKU",
            placeholderValue: "SKU",
            modelValue: _ctx.model.sku,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.sku) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createBlock(_component_form_field, {
            type: "select-string",
            labelKey: "Bag Type",
            labelValue: "Bag Type",
            placeholderKey: "Bag Type",
            placeholderValue: "Bag Type",
            list: _ctx.bagTypes,
            modelValue: _ctx.selectedBagType,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedBagType) = $event)),
            key: _ctx.rndtypes
          }, null, 8, ["list", "modelValue"]))
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(), _createBlock(_component_form_field, {
            type: "select-string",
            labelKey: "Main Body Material",
            labelValue: "Main Body Material",
            placeholderKey: "Main Body Material",
            placeholderValue: "Main Body Material",
            list: _ctx.mainMaterialsList,
            modelValue: _ctx.selectedMainMaterial,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedMainMaterial) = $event)),
            key: _ctx.rndtypes
          }, null, 8, ["list", "modelValue"]))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        class: "btn btn-tertiary",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.createEstimation && _ctx.createEstimation(...args))),
        disabled: _ctx.disabled
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Continue",
          text: "Continue"
        })
      ], 8, _hoisted_8)
    ])
  ]))
}