
import { Options, Vue } from 'vue-class-component';
import { BagEstimationClient, GarmentClient } from '@/services/Services';
import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Start extends Vue {

    selectedGarment: string = "";
    model: OM.CreateEstimationVM = new OM.CreateEstimationVM();
    bagConfiguration: OM.BagConfiguration = new OM.BagConfiguration();

    selectedBagType: string = "";
    selectedMainMaterial: string = "";
    selectedProcessType: string = "";
    rndtypes: number = 0;
    rndProcesses: number = 0;

    @Watch('$route.params.garmentIdentifier')
    updateGarment() {
        this.init();
    }
    
    created() {
        this.init();
    }

    init() {
        Promise.all([
            GarmentClient.getGarmentTextIdentifier(this.$route.params.garmentIdentifier as string),
            GarmentClient.getBagConfiguration(this.$route.params.garmentIdentifier as string)
        ])
        .then( xs => {
            this.model.garmentIdentifier = this.$route.params.garmentIdentifier as string;
            this.selectedGarment = xs[0].text;
            this.bagConfiguration = xs[1];
        })
    }

    get bagTypes() {
        var ris = [];

        this.bagConfiguration.bagTypes.forEach(element => {
            ris.push(element.name);
        });

        return ris;
    }

    get mainMaterialsList() {
        var ris = [];

        if(this.selectedTypeConfiguration) {
            this.selectedTypeConfiguration.garmentConfigurationPropertyLayers.forEach(element => {
                element.propertyValues[0].values.forEach(mainMaterialConfig => {
                    ris.push(mainMaterialConfig.label);
                });
            });

            this.selectedProcessType = this.selectedTypeConfiguration.bagProcessTypes[0].name;
        }

        return ris;
    }
    
    get selectedTypeConfiguration() {
        var filteredTypes = this.bagConfiguration.bagTypes.filter(x => x.name == this.selectedBagType);

        if(filteredTypes.length == 0)
            return null;
        else
            return filteredTypes[0];
    }

    get disabled(){
        return !this.model.garmentIdentifier || !this.model.sku || !this.selectedBagType || !this.selectedMainMaterial || !this.selectedProcessType;
    }

    createEstimation(){
        BagEstimationClient.createNewEstimation(this.model)
        .then(x => {
            var modelForUpdate = new OM.BagEstimationBaseInformationVM();
            modelForUpdate.identifier = x;
            modelForUpdate.bagType = this.selectedBagType;
            modelForUpdate.mainMaterial = this.selectedMainMaterial;
            modelForUpdate.processType = this.selectedProcessType;

            BagEstimationClient.updateBasicInfo(modelForUpdate)
            .then(x => {
                this.$router.push("/bag-calculator/components-weights/" + modelForUpdate.identifier);
            })
        })
    }
}
